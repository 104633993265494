import { Component, Suspense } from "react";
import Loadable from "react-loadable";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";

//import "../../node_modules/font-awesome/scss/font-awesome.scss";
import Aux from "../hoc/_Aux";
import directus from "../hoc/useDirectus";
import * as actionTypes from "../store/actions";
import Loader from "./layout/Loader";
import ScrollToTop from "./layout/ScrollToTop";

const AdminLayout = Loadable({
  loader: () => import("./layout/AdminLayout"),
  loading: Loader,
});

const SigninLayout = Loadable({
  loader: () => import("../Demo/Authentication/SignIn/SignIn.js"),
  loading: Loader,
});

const SignupLayout = Loadable({
  loader: () => import("../Demo/Authentication/SignUp/SignUp.js"),
  loading: Loader,
});

const ForgotPasswordLayout = Loadable({
  loader: () =>
    import("../Demo/Authentication/ForgotPassword/ForgotPassword.js"),
  loading: Loader,
});

const ResetPasswordLayout = Loadable({
  loader: () => import("../Demo/Authentication/ResetPassword/ResetPassword.js"),
  loading: Loader,
});

const ProtectedRoute = ({ component: Component, isAuthenticated }) => {
  return (
    <Route
      render={() =>
        isAuthenticated ? (
          <Component exact={true} />
        ) : (
          <Redirect
            to={{
              pathname: "/auth/signin",
              state: { from: "/" },
            }}
          />
        )
      }
    />
  );
};

class App extends Component {
  constructor(props) {
    super(props);
    //console.log("PROPS", props);
    this.state = { loading: true };
  }

  componentDidMount() {
    // Check if user's already signed in on page refresh
    directus.isLoggedIn().then((loggedIn) => {
      if (loggedIn) this.props.setSignIn();
      this.setState({ loading: false });
    });
  }
  render() {
    return (
      <Aux>
        <ScrollToTop>
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route path="/auth/signin" component={SigninLayout} />
              <Route path="/auth/signup" component={SignupLayout} />
              <Route
                path="/auth/forgot-password"
                component={ForgotPasswordLayout}
              />
              <Route
                path="/auth/reset-password"
                component={ResetPasswordLayout}
              />
              {!this.state.loading && (
                <ProtectedRoute
                  path="/"
                  component={AdminLayout}
                  isAuthenticated={this.props.isAuthenticated}
                />
              )}
            </Switch>
          </Suspense>
        </ScrollToTop>
      </Aux>
    );
  }
}

//export default App;
const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSignIn: () => dispatch({ type: actionTypes.AUTH_SIGNIN }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
