import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { createStore } from "redux";

import App from "./App/index";
import config from "./config";
import "./i18n";
import * as serviceWorker from "./serviceWorker";
import reducer from "./store/reducer";

// Place this at the top of index.js or App.js
if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .getRegistrations()
    .then((registrations) => {
      for (let registration of registrations) {
        registration.unregister();
      }
    })
    .catch((error) => {
      console.error("Error unregistering service workers:", error);
    });
}

const store = createStore(reducer);

/* export const directus = new DirectusSDK({
  url: "https://rest.juicedupmedia.com/",
  project: "juicedupmedia",
  persist: true,
  storage: window.localStorage,
  tokenExpirationTime: 10 * 12 * 50000,
  mode: "jwt",
});

window.directus = directus; */

const app = (
  <Provider store={store}>
    <BrowserRouter basename={config.basename}>
      {/* basename="/datta-able" */}
      <App />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
