export default {
  defaultPath: "/dashboard",
  basename: "/", // only at build time to set, like /datta-able
  layout: "vertical", // vertical, horizontal (not available in lite version)
  preLayout: null, // (not available in lite version)
  collapseMenu: false, // mini-menu
  layoutType: "menu-light", // menu-dark, (menu-light, dark are not available in lite version)
  navIconColor: false,
  headerBackColor: "header-default", // header-default, (header-blue, header-red, header-purple, header-lightblue, header-dark are not available in lite version)
  navBackColor: "navbar-default", // navbar-default, (navbar-blue, navbar-red, navbar-purple, navbar-lightblue, navbar-dark are not available in lite version)
  navBrandColor: "brand-default", // brand-default, (brand-blue, brand-red, brand-purple, brand-lightblue, brand-dark are not available in lite version)
  navBackImage: false, // not available in lite version
  rtlLayout: false, // not available in lite version
  navFixedLayout: true,
  headerFixedLayout: false, // not available in lite version
  boxLayout: false,
  navDropdownIcon: "style1", // style1, (style2, style3 are not available in lite version)
  navListIcon: "style1", // style1, (style2, style3, style4, style5, style6 are not available in lite version)
  navActiveListColor: "active-default", // active-default, (active-blue, active-red, active-purple, active-lightblue, active-dark are not available in lite version)
  navListTitleColor: "title-default", // title-default, (title-blue, title-red, title-purple, title-lightblue, title-dark are not available in lite version)
  navListTitleHide: false, // not available in lite version
  configBlock: false, // not available in lite version
  layout6Background:
    "linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)", // used only for pre-layout = layout-6
  layout6BackSize: "", // used only for pre-layout = layout-6
};
