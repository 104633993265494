import { Directus } from "@directus/sdk";
import DirectusSDK from "@directus/sdk-js";
// main client instance
/*const client = new DirectusSDK({
  url: "https://demo-api.directus.app/",
});*/
let url = "http://159.89.7.114/";
let project = "";
export const directusV9 = new Directus(url);

url = "https://rest.juicedupmedia.com/";
project = "juicedupmedia";
const directus = new DirectusSDK({
  url,
  project,
  persist: true,
  tokenExpirationTime: 10 * 12 * 50000,
  storage: window.localStorage,
  mode: "jwt",
});
export default directus;
export async function refreshTokenIfNeeded() {
  try {
    // Refresh the token
    await directus.auth.refresh();
  } catch (error) {
    console.error("Failed to refresh token:", error);
    // Handle logout or redirect to login page here
  }
}
// define the loading states
/*export var ResponseState;
(function (ResponseState) {
  ResponseState["LOADING"] = "loading";
  ResponseState["ERRORED"] = "errored";
  ResponseState["SUCCESS"] = "success";
})(ResponseState || (ResponseState = {}));
export function useDirectus(fn, opts) {
  // save error and response state internally
  const [response, setResponse] = useState();
  const [error, setError] = useState();
  useEffect(() => {
    // will call the API with your provided fn
    const fetcher = async () => {
      try {
        const result = await fn(client);
        // set the response if everything's allright
        setResponse(result);
      } catch (err) {
        // set the error if something went wrong
        setError(err);
      }
    };
    // execute!
    fetcher();
  }, []);
  return [
    response,
    error,
    error
      ? ResponseState.ERRORED
      : response
      ? ResponseState.SUCCESS
      : ResponseState.LOADING,
  ];
}
*/
